export function mapFile(file, overrides = {}) {//eslint-disable-line max-statements
  const sessionStore = useSessionStore();
  const currentUser = sessionStore.currentUser;
  const isAuthenticated = sessionStore.isAuthenticated;
  const isFileOwner = file.user_id === currentUser?.id;
  const isFileCopied = file.original_user_uuid && file.original_user_uuid !== file.user_id;

  file.id = file.forever_file_id || file.id; //for sanity's sake

  file.is = {};

  file.is.detail = overrides.isDetail || false;

  file.is.ownedByCurrentUser = isFileOwner;

  //types
  const declaredFileType = (file.type || file.fileType || '').toLowerCase();

  file.is.project = overrides.isProject || declaredFileType === FILE_TYPES.project;
  file.is.photo = overrides.isPhoto || declaredFileType === FILE_TYPES.photo;
  file.is.document = overrides.isDocument || declaredFileType === FILE_TYPES.document;
  file.is.video = overrides.isVideo || declaredFileType === FILE_TYPES.video;
  file.is.audio = overrides.isAudio || declaredFileType === FILE_TYPES.audio;
  file.is.gif = file.file_extension === 'gif';
  file.is.tif = file.file_extension === 'tif' || file.file_extension === 'tiff';

  file.is.printablePhoto = file.is.photo && file.width && file.height;

  //statuses
  file.is.processing = file.status === STATUSES.processing;
  file.is.errored = file.status === STATUSES.errored;
  file.is.finished = file.status === STATUSES.finished;
  file.is.previewUnavailable = file.status === STATUSES.previewUnavailable;
  file.is.faceRecProcessing = file.facial_recognition_status === FILE_FACE_REC_STATUS.indexed;

  //subtypes and attributes
  file.is.multiPaged = file.pages_count && file.pages_count > 1 && !file.is.previewUnavailable && !file.is.errored;
  file.is.jupiterProject = file.is.project && file.upload_source?.includes('jupiter');
  file.is.artisanProject = file.is.project && (file.upload_source?.includes('artisan') || file.upload_source?.includes('panstoria')); //note: panstoria is legacy
  file.is.mediaclipProject = file.is.project && file.upload_source?.includes('mediaclip');

  file.projectCreatedWith = (() => {
    if (file.is.jupiterProject) {
      return 'FOREVER® Create & Print';
    } else if (file.is.artisanProject) {
      return 'FOREVER Artisan®';
    } else if (file.is.mediaclipProject) {
      return 'FOREVER® Projects';
    }
  })();

  //upload source in a readable format (note: Projects have their own projectCreatedWith attribute)
  file.uploadSourceFriendly = mapUploadSource(file.upload_source);

  file.is.streamable = file.streamable;

  //jupiter project states
  file.is.jupiterProjectInProgress = file.is.jupiterProject && file.provider_status === PROJECT_STATUSES.inProgress;
  file.is.jupiterProjectInCart = file.is.jupiterProject && file.provider_status === PROJECT_STATUSES.inCart;
  file.is.jupiterProjectPrintable = file.is.jupiterProject && file.provider_status === PROJECT_STATUSES.printable;
  file.is.jupiterProjectOrdered = file.is.jupiterProject && file.provider_status === PROJECT_STATUSES.ordered;

  /* USER CONTEXT PROPERTIES*/
  file.is.editablePhoto = isFileOwner && file.is.photo && !file.is.errored;
  file.is.editableVideo = isFileOwner && file.is.video && !file.is.errored;
  file.is.downloadable = !file.is.jupiterProject || file.is.jupiterProjectPrintable;
  file.is.downloadableWithExif = file.is.downloadable && (file.is.photo || file.is.document);
  file.is.copyableFromUser = (!file.is.jupiterProject || file.is.jupiterProjectPrintable) && (isAuthenticated && !isFileOwner); //can a user copy to the Library from another user's file?
  file.is.copyableFromCurrentUser = (!file.is.jupiterProject || file.is.jupiterProjectPrintable) && (isAuthenticated && isFileOwner); //can a user copy files from their library into a collab album?
  file.is.ownedByOriginalOwner = !isFileCopied;

  file.is.editableJupiterProject = isFileOwner && file.is.jupiterProject && !isFileCopied;
  file.is.copyableJupiterProject = file.is.jupiterProjectInProgress || file.is.jupiterProjectInCart;

  //determines whether a project is purchasable
  file.is.purchasable = file.is.project && ( //is a project
    !file.is.jupiterProject || //artisan
    (
      isFileOwner || //jupiter owner
      file.provider_status === PROJECT_STATUSES.printable //jupiter other
    )
  );

  file.is.purchased = file.is.jupiterProjectPrintable || file.is.jupiterProjectOrdered;

  //determines if a Jupiter project can be shared
  const isShareableJupiterProject = file.is.jupiterProject && isFileOwner && (
    file.provider_status === PROJECT_STATUSES.printable ||
    file.provider_status === PROJECT_STATUSES.ordered
  );

  //can a given file be shared?
  file.is.sharable = (!file.is.jupiterProject && isFileOwner) || isShareableJupiterProject;

  //MAP INCLUDED ENTITIES
  if (file.tags) {
    file.tags = file.tags.map(tagMapper);
  }

  if (file.albums) {
    file.albums = file.albums.map(albumMapper);
  }

  if (file.people) {
    file.people = file.people = file.people.map(p => personMapper(p, file));
  }

  if (file.user) {
    file.user = userMapper(file.user);
  }

  if (file.uploaded_by) {
    file.uploaded_by = userMapper(file.uploaded_by);
  }

  return file;
}
